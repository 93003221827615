import allParamsJSON from '~/components/inc/all_params.json'
import _ from "lodash"
import { uuid } from 'vue-uuid'
import { setTimeout } from 'core-js'

export const economics = {
    namespaced: true,
    state: () => ({
        options: {
            caseData: {},
            caseTypes: [
                {
                    id: 'oil',
                    name: 'economics.type.oil',
                    isActive: false
                },
                {
                    id: 'gas',
                    name: 'economics.type.gas',
                    isActive: false
                }
            ],
            fixed: {
                isActive: true,
                value: 'p50'
            },
            resultIterationsCount: [
                {
                    name: '1', id: '1', isActive: false
                },
                {
                    name: '1000', id: '1000', isActive: false
                },
                {
                    name: '2000', id: '2000', isActive: false
                },
                {
                    name: '5000', id: '5000', isActive: false
                }
            ],
            resultProbability: {
                id: 'p50'
            },
            tabs: [
                {
                    id: 'settings',
                    title: 'economics.tabs.settings',
                    isActive: false
                },
                {
                    id: 'schedule',
                    title: 'economics.tabs.schedule',
                    isActive: false
                },
                {
                    id: 'production',
                    title: 'economics.tabs.production',
                    isActive: false
                },
                {
                    id: 'sales',
                    title: 'economics.tabs.revenues',
                    isActive: false
                },
                {
                    id: 'capex',
                    title: 'economics.tabs.capex',
                    isActive: false
                },
                {
                    id: 'opex',
                    title: 'economics.tabs.opex',
                    isActive: false
                },
                {
                    id: 'fiscal',
                    title: 'economics.tabs.fiscal',
                    isActive: false
                },
                {
                    id: 'analytics',
                    title: 'economics.tabs.analytics',
                    isActive: false
                },
                {
                    id: 'table',
                    title: 'economics.tabs.table',
                    isActive: false
                }
            ]
        },
        engineeringCase: null,
        geoCase: null,
        result: {
            current: {},
            id: null,
            individual: null,
            analytics: null
        },
        resultTable: null,
        settings: {
            settings: null,
            data: null
        },
        taxRegimeId: null,
        schedule: {
            settings: null,
            data: null
        },
        production: {
            settings: null,
            data: {
                products: null,
                props: null
            }
        },
        sales: {
            currencies: {
                settings: null,
                data: null,
                new: null
            },
            prices: {
                export: {
                    settings: null,
                    data: null
                },
                domestic: {
                    settings: null,
                    data: null
                }
            },
            shares: {
                settings: null,
                data: null
            }
        },
        capex: {
            wells: {
                settings: null,
                data: null
            },
            infrastructures: {
                settings: null,
                data: null,
                new: null
            }
        },
        opex: {
            costs: {
                settings: null,
                data: null,
                new: null
            }
        }
    }),
    getters: {
        allCaseData: (state) => {
            return {
                result_quantity: state.options.resultIterationsCount.find(tab => tab.isActive).id,
                tax_regime_id: state.taxRegimeId,
                settings: state.settings.data ? Object.keys(state.settings.data).reduce((cur, key) => {
                    return Object.assign(cur, {
                        [key]: state.settings.data[key].value
                    }
                )}, {}) : null,
                data_schedule: state.schedule.data ? state.schedule.data : null,
                engineer_case_id: state.engineeringCase ? state.engineeringCase.id : null,
                data_production: {
                    products: state.production.data.products ? state.production.data.products : null,
                    props: state.production.data.props
                },
                data_currencies: state.sales.currencies.data ? state.sales.currencies.data : null,
                data_prices: {
                    export: state.sales.prices.export.data ? state.sales.prices.export.data : null,
                    domestic: state.sales.prices.domestic.data ? state.sales.prices.domestic.data : null
                },
                data_shares: state.sales.shares.data ? state.sales.shares.data : null,
                data_capex: {
                    wells: state.capex.wells.data ? state.capex.wells.data : null,
                    infrastructures: state.capex.infrastructures.data ? state.capex.infrastructures.data : null
                },
                data_opex: {
                    costs: state.opex.costs.data ? state.opex.costs.data : null
                }
            }
        }
    },
    mutations: {
        saveResult(state, payload) {
            const { result } = payload
            state.result.current = result
        },
        saveAnalyticsResult(state, payload) {
            const { result } = payload
            state.result.analytics = result
        },
        updateResultProbability(state, payload) {
            state.options.resultProbability = payload
        },
        setCaseData(state, payload) {
            payload.caseId ? state.options.caseData.caseId = payload.caseId : false
            payload.projectId ? state.options.caseData.projectId = payload.projectId : false
        },
        setCaseType(state, payload) {
            state.options.caseTypes.map(item => item.id === payload ? item.isActive = true : item.isActive = false)
        },
        setActiveCaseTab(state, payload) {
            const tabId = payload
            state.options.tabs.forEach(tab => {
                tab.isActive = tab.id === tabId ? true : false
            })
        },
        updateResultIterationsCount(state, payload) {
            const count = payload

            state.options.resultIterationsCount.forEach(element => {
                if (Number(element.id) === Number(count)) {
                    element.isActive = true
                } else {
                    element.isActive = false
                }
            })
        },
        toggleFixedCase(state, bool) {
            if (bool) {
                this.commit('economics/updateResultIterationsCount', 1)
            } else {
                const iterationsCount = state.options.resultIterationsCount.find(tab => tab.isActive).id
                this.commit('economics/updateResultIterationsCount', Number(iterationsCount) === 1 ? 1000 : iterationsCount)
            }
            state.options.fixed.isActive = bool
        },
        setFixedCaseOption(state, payload) {
            const probabilityId = payload.probability
            const caseType = state.options.caseTypes.find(type => type.isActive).id

            let caseData = {
                production: {
                    products: state.production.data.products,
                    props: state.production.data.props
                },
                currencies: state.sales.currencies.data,
                prices: {
                    export: state.sales.prices.export.data,
                    domestic: state.sales.prices.domestic.data
                },
                shares: state.sales.shares.data,
                wells: state.capex.wells.data,
                infrastructures: state.capex.infrastructures.data,
                costs: state.opex.costs.data
            }

            const setFixedPropValue = (prop, key) => {
                if (!prop.type_param) return

                if (prop.type_param === 'distribution') {
                    prop.value = prop.multiple_values[probabilityId]
                }
                if (prop.type_param === 'deviation') {
                    if (probabilityId === 'mean') prop.value = prop.value
                    if (probabilityId === 'min') prop.value = (prop.value * (1 - prop.deviation / 100)).toFixed(2)
                    if (probabilityId === 'max') prop.value = (prop.value * (1 + prop.deviation / 100)).toFixed(2)

                    if (probabilityId.startsWith('p')) {
                        const min = Number((prop.value * (1 - prop.deviation / 100)).toFixed(2))
                        const max = Number((prop.value * (1 + prop.deviation / 100)).toFixed(2))
                        const difference = Math.abs(max - min)
                        const valueProbability = 1 - Number(probabilityId.substr(1)) / 100

                        if (prop.distribution === 'uniform') prop.value = (min + valueProbability * difference).toFixed(2)
                        if (prop.distribution === 'triangular') {
                            const F = Number(((prop.value - min) / (max - min)).toFixed(2))

                            if (valueProbability <= F) {
                                prop.value = (min + Math.sqrt( valueProbability * (max - min) * (prop.value - min))).toFixed(2)
                            } else {
                                prop.value = (max - Math.sqrt( (1 - valueProbability) * (max - min) * (max - prop.value))).toFixed(2)
                            }

                        }

                    }
                }
                prop.type_param = 'fixed'
            }

            const valueKeys = ['price', 'rate', 'duration', 'inflation', 'export_share']
            const loopCaseData = (key) => {
                let object = _.get(caseData, key)

                if (key === 'production') {
                    for (let productKey in object.products) {
                        setFixedPropValue(object.products[productKey], key + productKey)

                        object.products[caseType].value_mode.mode === 'all' ? object.products[caseType].value_mode.mode = probabilityId : false
                    }
                    for (let productKey in object.props) {
                        setFixedPropValue(object.props[productKey], key + productKey)
                    }
                } else {
                    for (let childKey in object) {
                        if (valueKeys.includes(childKey) && typeof object[childKey] === 'object') {
                            setFixedPropValue(object[childKey], key + childKey)
                        } else if (typeof object[childKey] === 'object') {
                            loopCaseData(`${key}.${childKey}`, key + childKey)
                        }
                    }
                }
            }

            for (let key in caseData) {
                loopCaseData(key)
            }

            state.result.analytics = null

            if (!payload.changeCurrentCase) return caseData
        },
        saveTaxRegime(state, id) {
            state.taxRegimeId = id
        },
        settingsGenerateProps(state, payload) {
            const dataCopy = _.cloneDeep(state.settings.data)

            state.settings.data = Object.keys(state.settings.settings.props).reduce((cur, key) => {
                return Object.assign(cur, {
                    ...(dataCopy ? {
                        value: dataCopy[key] ? dataCopy[key].value : state.settings.settings.props[key].default_value
                    } : {
                        [key]: {
                            value: state.settings.settings.props[key].default_value
                        }
                    })
                }
            )}, {})
        },
        scheduleAddStep(state, payload) {
            const { key, data } = payload

            if (!state.schedule.data) state.schedule.data = {}
            state.schedule.data[key] = data
        },
        scheduleRemoveStep(state, key) {
            delete state.schedule.data[key]
        },
        productionSetEngineeringCase(state, payload) {
            if (!payload) {
                state.engineeringCase = null
                const capexWellsFromCase = !state.capex.wells.data ? [] : Object.keys(state.capex.wells.data).filter(key => state.capex.wells.data[key].count_mode === 'from_case')

                capexWellsFromCase.forEach(key => this.commit('economics/capexRemoveWell', key))
                return
            }
            const { engineer_case: engineeringCase, geo_case: geoCase } = payload

            state.engineeringCase = engineeringCase
            geoCase ? state.geoCase = geoCase : false
        },
        productionAddProduct(state, payload) {
            const key = payload.key
            const data = payload.data
            const props = data.props

            if (!state.production.data.products) state.production.data.products = {}

            if (props) {
                if (!state.production.data.props) state.production.data.props = {}
                props.forEach(prop => {
                    if (!Object.keys(state.production.data.props).includes(prop)) {
                        state.production.data.props[prop] = state.production.settings.props[prop].default_value
                    }
                })
            }

            state.production.data.products[key] = data
        },
        productionRemoveProduct(state, key) {
            delete state.production.data.products[key]
        },
        salesAddNewCurrency(state, payload) {
            const data = payload
            state.sales.currencies.new = data
        },
        salesSaveNewCurrency(state) {
            const id = state.sales.currencies.new.selected.id || state.sales.currencies.new.selected
            let currency = _.cloneDeep(state.sales.currencies.new)

            delete currency.selected

            if (currency.main) {
                for (let key in state.sales.currencies.data) {
                    key === id ? state.sales.currencies.data[key].main = true : state.sales.currencies.data[key].main = false
                }
            }

            if (!state.sales.currencies.data) state.sales.currencies.data = {}
            state.sales.currencies.data[id] = currency

            this.commit('economics/salesSortCurrencies')
        },
        salesRemoveCurrency(state, key) {
            delete state.sales.currencies.data[key]
            if (!Object.keys(state.sales.currencies.data).length) state.sales.currencies.data = null
        },
        salesChangeMainCurrency(state, payload) {
            const id = payload

            if (!state.sales.currencies.data) return

            for (let key in state.sales.currencies.data) {
                key === id ? state.sales.currencies.data[key].main = true : state.sales.currencies.data[key].main = false
            }

            this.commit('economics/salesSortCurrencies')
        },
        salesSortCurrencies(state) {
            state.sales.currencies.data = Object.keys(state.sales.currencies.data)
                .sort((a, b) => state.sales.currencies.data[a].main ? -1 : 1)
                .reduce((cur, key) => {
                return Object.assign(cur, {
                    [key]: state.sales.currencies.data[key]
                }
            )}, {})
        },
        salesSetShares(state, payload) {
            const { keys, keysToRemove } = payload

            if (keysToRemove) {
                keysToRemove.forEach(key => {
                    delete state.sales.shares.data[key]
                })
                return
            }

            const prices = Object.keys(state.production.data.products)
                .filter(key => keys ? keys.includes(key) : true)
                .filter(key => Object.keys(state.sales.prices.export.data).includes(key) && Object.keys(state.sales.prices.domestic.data).includes(key))
                .reduce((cur, key) => {
                return Object.assign(cur, {
                    [key]: {
                        export_share: {
                            ...state.sales.shares.settings.props.export_share.default_value
                        }
                    }
                }
            )}, {})

            if (keys) {
                state.sales.shares.data = {
                    ...state.sales.shares.data,
                    ...prices
                }
            } else {
                state.sales.shares.data = prices
            }
        },
        capexAddWell(state, payload) {
            const { key, data } = payload

            if (!state.capex.wells.data) state.capex.wells.data = {}
            state.capex.wells.data[key] = data
        },
        capexRemoveWells(state) {
            state.capex.wells.data = null
        },
        capexRemoveWell(state, key) {
            delete state.capex.wells.data[key]
            if (!Object.keys(state.capex.wells.data).length) state.capex.wells.data = null
        },
        capexAddNewBuilding(state, payload) {
            const data = payload
            state.capex.infrastructures.new = data
        },
        capexSaveNewBuilding(state) {
            let building = _.cloneDeep(state.capex.infrastructures.new)

            if (!state.capex.infrastructures.data) state.capex.infrastructures.data = {}
            state.capex.infrastructures.data[uuid.v4()] = building

        },
        capexRemoveBuilding(state, key) {
            delete state.capex.infrastructures.data[key]
            if (!Object.keys(state.capex.infrastructures.data).length) state.capex.infrastructures.data = null
        },
        opexAddNewCost(state, payload) {
            const data = payload
            state.opex.costs.new = data
        },
        opexSaveNewCost(state) {
            let cost = _.cloneDeep(state.opex.costs.new)

            if (!state.opex.costs.data) state.opex.costs.data = {}
            state.opex.costs.data[uuid.v4()] = cost
            state.opex.costs.new = null
        },
        opexRemoveCost(state, key) {
            delete state.opex.costs.data[key]
            if (!Object.keys(state.opex.costs.data).length) state.opex.costs.data = null
        },
        resetCaseResult(state) {
            state.result = {
                current: {},
                id: null,
                individual: null,
                analytics: null
            }
        },
        removeAnalyticsResult(state) {
            state.result.analytics = null
        },
    },
    actions: {
        async getCase({ state, getters } , payload) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/economics/${state.options.caseData.caseId}`)

            if (error.value) return {
                error: true,
                errorMessage: error.value.data.message
            }

            const caseData = data.value

            this.commit('economics/setCaseType', caseData.type)
            this.commit("common/setEconomicsCaseOptions", {
                name: caseData.name,
                id: caseData.id
            })

            await this.dispatch('economics/getSettings')

            if (caseData.settings) {
                state.settings.data = Object.keys(caseData.settings).reduce((cur, key) => {
                    return Object.assign(cur, {
                        [key]: {
                            value: caseData.settings[key]
                        }
                    }
                )}, {})

                if (Object.keys(caseData.settings).length !== Object.keys(state.settings.settings.props).length) {
                    this.commit('economics/settingsGenerateProps')
                }
            } else {
                this.commit('economics/settingsGenerateProps')
            }

            this.commit('economics/updateResultIterationsCount', caseData.result_quantity || 1000)

            if (caseData.new) {
                this.commit('economics/setActiveCaseTab', 'settings')
                this.commit('common/setLoadingStatus', false)
                return
            }

            if (caseData.tax_regime_id) {
                state.taxRegimeId = caseData.tax_regime_id
            }

            if (caseData.data_schedule) {
                state.schedule.data = caseData.data_schedule
            }
            if (caseData.data_production) {
                state.production.data = caseData.data_production

                const caseTypeId = state.options.caseTypes.find(type => type.isActive).id

                if (state.production.data.products[caseTypeId].type_param) {
                    delete state.production.data.products[caseTypeId].type_param
                }

                if (!state.production.data.products[caseTypeId].value_mode) {
                    state.production.data.products[caseTypeId].value_mode = {
                        mode: 'mean',
                        row_id: null,
                        rows_options: []
                    }
                }

                // temp
                if (state.production.data.products['commercial_gas']) delete state.production.data.products['commercial_gas']
            }
            if (caseData.data_currencies) {
                state.sales.currencies.data = caseData.data_currencies
            }
            if (caseData.data_prices) {
                state.sales.prices.export.data = caseData.data_prices.export
                state.sales.prices.domestic.data = caseData.data_prices.domestic
            }
            if (caseData.data_shares) {
                state.sales.shares.data = caseData.data_shares
            }

            if (caseData.data_capex) {
                let capexWells = caseData.data_capex.wells

                //temp
                if (capexWells && Object.keys(capexWells).length) {
                    capexWells = Object.keys(caseData.data_capex.wells).reduce((cur, key) => {
                        return Object.assign(cur, {
                            [key]: {
                                ...caseData.data_capex.wells[key],
                                ...(caseData.data_capex.wells[key].count_mode === 'from_case' && caseData.data_capex.wells[key].active === undefined ? {
                                    active: true
                                } : {})
                            }
                        })
                    }, {})
                }
                //end temp
                
                state.capex.wells.data = capexWells
                state.capex.infrastructures.data = caseData.data_capex.infrastructures
            }
            if (caseData.data_opex) {
                state.opex.costs.data = caseData.data_opex.costs
            }
            if (caseData.actual_job) {
                this.commit('economics/saveResult', {
                    result: caseData.actual_job.result
                })
                state.result.id = caseData.actual_job.id
            }
            if (caseData.actual_analytics_job) {
                this.commit('economics/saveAnalyticsResult', {
                    result: caseData.actual_analytics_job.result
                })
            }
            if (caseData.engineer_case) {
                state.engineeringCase = {
                    name: caseData.engineer_case.name,
                    id: caseData.engineer_case_id,
                    fixed: caseData.engineer_case.fixed,
                    data: caseData.engineer_case.data,
                    wc: caseData.engineer_case.wc
                }

                if (caseData.engineer_case.geo_case) {
                    state.geoCase = {
                        id: caseData.engineer_case.geo_case.id,
                        name: caseData.engineer_case.geo_case.name,
                        layer_id: caseData.engineer_case.geo_case.layer_id,
                        object_id: caseData.engineer_case.geo_case.object_id
                    }
                }
            }

            this.commit('economics/setActiveCaseTab', 'production')
            this.commit('common/setLoadingStatus', false)
        },
        async getSettings({ state }, payload) {
            const { production, currencies, prices, capex, settings, opex } = await $fetch(`/api/projects/${state.options.caseData.projectId}/economics/${state.options.caseData.caseId}/settings`)

            state.settings.settings = settings
            state.production.settings = production
            state.sales.currencies.settings = currencies
            state.sales.prices.export.settings = _.cloneDeep(prices)
            state.sales.prices.domestic.settings = _.cloneDeep(prices)
            state.sales.shares.settings = _.cloneDeep(prices)
            state.capex.wells.settings = _.cloneDeep(capex)
            state.capex.infrastructures.settings = _.cloneDeep(capex)
            state.opex.costs.settings = opex

            const obj = {
                production: {
                    products: {},
                    props: {}
                },
                sales: {
                    currencies: {},
                    prices: {
                        export: {},
                        domestic: {}
                    },
                    shares: {}
                },
                capex: {
                    wells: {},
                    infrastructures: {}
                },
                opex: {
                    costs: {}
                }
            }

            const loop = (key) => {
                let object = _.get(obj, key)

                for (let childKey in object) {
                    if (typeof object[childKey] === "object" && Object.keys(object[childKey]).length) {
                        loop(`${key}.${childKey}`)
                    } else {
                        const fullPath = `${key}.${childKey}`
                        setParamsSettings(fullPath)
                    }
                }
            }

            const setParamsSettings = (path) => {
                const settings = _.get(allParamsJSON, `economics.${path}`)
                
                if (settings) {
                    const mainKey = path.split('.')[0]

                    if (mainKey === 'production') {
                        const lastKey = path.split('.')[path.split('.').length - 1]

                        let objectInState = _.get(state, `production.settings.${lastKey}`)
                        let objectInStateKeys = Object.keys(objectInState)

                        objectInStateKeys.forEach(key => {
                            _.set(state, `production.settings.${lastKey}.${key}.settings`, {
                                    ...objectInState[key].settings,
                                    ...settings[key]
                            })
                        })

                    } else {
                        let objectInState = _.get(state, `${path}.settings.props`)
                        let objectInStateKeys = Object.keys(objectInState)

                        objectInStateKeys.forEach(key => {
                            if (settings[key]) {
                                _.set(state, `${path}.settings.props.${key}.settings`, {
                                    ...objectInState[key].settings,
                                    ...settings[key]
                                })
                                if (settings[key].default_value) {
                                    _.set(state, `${path}.settings.props.${key}.default_value`, {
                                        ...objectInState[key].default_value,
                                        value: settings[key].default_value
                                    })
                                }
                            }
                        })
                    }
                }
            }

            for (let key in obj) loop(key)
        },
        async updatePropertyState({ state }, payload) {
            const part = payload.part
            const propId = payload.id
            const data = payload.data

            _.set(state, `${part}.${propId}`, {
                ...data,
               distribution: data.distribution.value
            })

            return true
        },
        async getResult({ state, getters }) {
            const currentTab =  state.options.tabs.find(tab => tab.isActive).id

            if (currentTab === 'sales' && state.options.fixed.isActive && (!state.sales.currencies.data || !state.sales.prices.export.data || !state.sales.prices.domestic.data || !state.sales.shares.data)) return

            this.commit('common/setRequestPendingStatus', true)

            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/economics/${state.options.caseData.caseId}/calc_init/${currentTab}`, {
                    method: 'POST',
                    body: JSON.stringify(getters.allCaseData)
                }
            )

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData',
                    {
                        statusCode: error.value.statusCode,
                        message: errorMessage.message,
                        showError: true
                    }
                )

                this.commit('common/blockInterface', {
                    isActive: false
                })

                this.commit('common/setRequestPendingStatus', false)

                return
            }

            useGtag().gtag('event', 'start_calculation', {
				'case_type': 'economics' 
			})

            try {
                state.result.id = data.value.job.id

                if (data.value.job.result && data.value.job.status === 2) {
                    if (Object.keys(data.value.job.result).length) {
                        this.commit('economics/saveResult', {
                            result: data.value.job.result
                        })
                    } else {
                        this.commit('common/setErrorData', {
                            statusCode: 500,
                            message: 'No result',
                            showError: true
                        })
                    }
                }

                this.commit('common/setRequestPendingStatus', false)
                this.commit('common/setLoadingStatus', false)
            } catch (e) {
                useNuxtApp().$economicCaseError('Error getting result', e)
            }
        },
        async getIndividualResult({ state }, rows) {
            const { data, pending, error, refresh } = await useFetch(`/api/jobs/${state.result.id}/rows/${rows}`)

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                return false
            }

            state.result.individual = data.value

            return 'true'
        },
        async getPartTable({ state, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/economics/${state.options.caseData.caseId}/get_tables/`, {
                method: 'POST',
                body: JSON.stringify(getters.allCaseData)
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                return false
            }

            return data.value
        }, 
        async getAnalyticsResult({ state, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/economics/${state.options.caseData.caseId}/calc_analytics_init/`, {
                method: 'POST',
                body: JSON.stringify(getters.allCaseData)
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                return false
            }
        },
        async getAnalyticsFixedResult({ state, getters }, payload) {
            const caseType = state.options.caseTypes.find(type => type.isActive).id

            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/economics/${state.options.caseData.caseId}/calc_analytics_fixed`, {
                method: 'POST',
                body: JSON.stringify({
                    case: {
                        case_type: caseType,
                        ...getters.allCaseData
                    },
                    params: payload
                })
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                return false
            }

            return data.value.result
        },
        salesSetPrices({ state, rootState } , payload) {
            const caseTypeId = state.options.caseTypes.find(type => type.isActive).id
            let { id, keys, keysToRemove } = payload

            if (keysToRemove) {
                keysToRemove.forEach(key => {
                    delete state.sales[id].data[key]
                })
                return
            }

            if (caseTypeId === 'gas' && id === 'export') {
                keys ? keys.push('oil') : false
            }

            const prices = [
                ...Object.keys(state.production.data.products),
                ...(caseTypeId === 'gas' && id === 'export' ? 
                    ['oil'] : []
                )].filter(key => keys ? keys.includes(key)  : true).reduce((cur, key) => {
                const color = (id === 'export') ? (key === 'oil' ? '#60d494' : key === 'oil_gas' ? '#ffda7d' : null) : (key === 'oil' ? '#abf684' : key === 'oil_gas' ? '#5d9bd5' : null)

                return Object.assign(cur, {
                    [key]: {
                        active: true,
                        mode: 'manual',
                        price: {
                            ...state.sales.prices[id].settings.props.price.default_value,
                            ...(key === 'oil' && Object.keys(state.sales.currencies.data)[0] === 'usd' ? {
                                value: 50
                            } : {})
                        },
                        table: null,
                        color: color || rootState.common.colorPack[Math.floor(Math.random() * (rootState.common.colorPack.length))],
                        currency: Object.keys(state.sales.currencies.data)[0],
                        inflation: true,
                        max_price: null
                    }
                }
            )}, {})

            if (keys) {
                state.sales.prices[id].data = {
                    ...state.sales.prices[id].data,
                    ...prices
                }
            } else {
                state.sales.prices[id].data = prices
            }
        },
    }
}